.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* 탑 상단 고정 밑 세로 길이 설정 */
.header {
  height: 35px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header img{
  margin-bottom:-6px;
}

.gift-link {
  float: right;
  width: 80%;
  text-align: left;
}
.top-link {
  float: right;
  width: 30px;
  text-align: center;
  padding: 10px;
}

/* 네비바 하단 고정 밑 세로 길이 설정 */
.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
}

/* nav태그 아래의 자식들을 수평정렬하기 위한 설정 */
nav {
  overflow: hidden;
  border-top: 1px solid gray;
  background: #FFFFFF;
  
  /*  디자인변경(2022-07-01)
  background: #dce1df;
  */

  z-index: 1000;
}

/* nav태그 아래의 div태그들을 수평정렬 및 세로길이 설정 */
.nav-link {
  /* 수평정렬, 5개의 button을 각각 20% width만큼 할당 */
  float: left;
  width: 33%;
  text-align: center;
  color: gray;

  /* 세로길이 설정 */
  height: 65px;
  line-height: 75px;
}


/* 하단 네비바의 메뉴를 눌렀을 때 색상 변경*/
.nav-item {
  color: grey;
}

/* 하단 네비바의 메뉴를 눌렀을 때 색상 변경*/
.active {
  color: #01AAF7;
}


/* 카드형게시판 시작 */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wlxdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7g.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlxdr.ttf) format('truetype');
}
body {
  background: #ffffff;
  /*  디자인변경(2022-07-01)
  background: #dce1df;
  */
  color: #4f585e;
  font-family: 'Source Sans Pro', sans-serif;
  text-rendering: optimizeLegibility;
}
a.btn {
  background: #0096a0;
  border-radius: 4px;
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: inline-block;
  padding: 6px 30px 8px;
  position: relative;
  text-decoration: none;
  transition: all 0.1s 0s ease-out;
}
a.btn2 {
  background: #5652a1;
  border-radius: 4px;
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: inline-block;
  padding: 6px 30px 8px;
  position: relative;
  text-decoration: none;
  transition: all 0.1s 0s ease-out;
}
.no-touch a.btn:hover {
  background: #00a2ad;
  box-shadow: 0px 8px 2px 0 rgba(0, 0, 0, 0.075);
  transform: translateY(-2px);
  transition: all 0.25s 0s ease-out;
}
.no-touch a.btn:active,
a.btn:active {
  background: #008a93;
  box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.25);
  transform: translate3d(0, 1px, 0);
  transition: all 0.025s 0s ease-out;
}
div.cards {
  /* margin: 80px auto; */
  margin: auto;
  margin-bottom: 80px;
  max-width: 960px;
  text-align: center;
}
div.card {
  background: #ffffff;
  display: inline-block;
  margin: 8px;
  max-width: 340px;
  perspective: 1000;
  position: relative;
  text-align: center;
  transition: all 0.3s 0s ease-in;
  width: 370px;
  z-index: 1;
}
.round{
  border-radius: 20px 20px 0px 0px;  
  border-bottom: solid 1px black;
}
div.card img {
  padding-top: 13px;
  max-width: 340px;
  max-height: 250px;
}
div.card .card__image-holder {
  background: rgba(0, 0, 0, 0);  
  /*  디자인변경(2022-07-01)
  background: rgba(0, 0, 0, 0.1);
  */
  height: 0;
  padding-bottom: 75%;
  text-align: center;
  border-top: solid 1px black;
  /*  디자인변경(2022-07-01)
  border-top: solid 1px black; 새로 추가
  */
}
div.card div.card-title {
  background: #ffffff;
  padding: 6px 15px 10px;
  position: relative;
  z-index: 0;
}
div.card div.card-title a.toggle-info {
  border-radius: 32px;
  height: 32px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 10px;
  width: 32px;
}
div.card div.card-title a.toggle-info span {
  background: #ffffff;
  display: block;
  height: 2px;
  position: absolute;
  top: 16px;
  transition: all 0.15s 0s ease-out;
  width: 12px;
}
div.card div.card-title a.toggle-info span.left {
  right: 14px;
  transform: rotate(45deg);
}
div.card div.card-title a.toggle-info span.right {
  left: 14px;
  transform: rotate(-45deg);
}
div.card div.card-title h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.05em;
  margin: 0;
  padding: 0;
}
div.card div.card-title h2 small {
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.025em;
}
div.card div.card-description {
  padding: 0 15px 10px;
  position: relative;
  font-size: 20px;
}
div.card div.card-actions {
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.075);
  padding: 10px 15px 20px;
  text-align: center;
}
div.card div.card-flap {
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  transform-origin: top;
  transform: rotateX(-90deg);
}
div.card div.flap1 {
  transition: all 0.3s 0.3s ease-out;
  z-index: -1;
}
div.card div.flap2 {
  transition: all 0.3s 0s ease-out;
  z-index: -2;
}
div.cards.showing div.card {
  cursor: pointer;
  opacity: 0.6;
  transform: scale(0.88);
}
.no-touch div.cards.showing div.card:hover {
  opacity: 0.94;
  transform: scale(0.92);
}
div.card.show {
  opacity: 1 !important;
  transform: scale(1) !important;
  z-index: 100;
  margin-top:100px;

  /* 카드형게시판 끝 */
  box-shadow : rgba(0,0,0,0.5) 0 0 0 9999px, rgba(0,0,0,0.5) 2px 2px 3px 3px;
}
div.card.show div.card-title a.toggle-info {
  background: #ff6666 !important;
}
div.card.show div.card-title a.toggle-info span {
  top: 15px;
}
div.card.show div.card-title a.toggle-info span.left {
  right: 10px;
}
div.card.show div.card-title a.toggle-info span.right {
  left: 10px;
}
div.card.show div.card-flap {
  background: #ffffff;
  transform: rotateX(0deg);
}
div.card.show div.flap1 {
  transition: all 0.3s 0s ease-out;
}
div.card.show div.flap2 {
  transition: all 0.3s 0.2s ease-out;
}
/* 카드형게시판 끝 */

.product-title {
    text-align:center;
    display:table;
    border:0px solid #cecece;
    width:100%;
}

.product-img-div {
    display:table-cell;
    vertical-align:middle;
}

.product-img {
    max-width:180px;
}

.gift_input {
    margin: 1px;
	width: 69%;
	height: 30px;
	text-align: center;
}

.gift_input_select {
    margin: 1px;
	width: 71%;
	height: 35px;
	text-align: center;
}


.use_select {
    margin: 1px;
	width: 28%;
	height: 35px;
	text-align: center;
	appearance:none /* 화살표 없애기 */
}

.pay_input {
    margin: 1px;
	width: 30%;
	height: 30px;
	text-align: center;
}

.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 320px;
  min-height: 500px;
  padding: 60px 25px;
  border-radius: 8px;
  text-align: center;
}

.title {
  line-height: 2.5rem;
  color: #f95c62;
  margin-bottom: 50px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form > input {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.form > input[type='email']:focus,
.form > input[type='password']:focus {
  border: 1px solid #f78287;
}

.login {
  cursor: pointer;
  background-color: #f78287;
  color: #000;
}

.newAccount {
  background-color: #008aff;
}

.account {
  cursor: pointer;
  font-size: 0.875rem;
  color: #000;
  transition: color 0.5s;
}

.account:hover {
  color: #000;
}

.googleLogin {
  font-size: 0.75rem;
  width: 100%;
  height: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.icon {
  margin-right: 5px;
}

@media (max-width: tiny) {
  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .googleLogin {
    margin-bottom: 100px;
  }
}

.toast {
  top: 150px;
  left: 30px;
  height: 70px;
  pointer-events: none;		/*마우스 이벤트 받지 않음.*/
}

.share_list {
  padding-top:30px;
}
.share_list2 {
  padding-top:60px;
}


/* 모달팝업 시작 */
.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  width: 90%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modal > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  background-color: #f1f1f1;
  font-weight: 700;
}
.modal > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modal > section > main {
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.modal > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 모달팝업 끝 */

.leftSpan {
  float: left;  
  padding-left: 10px;
  padding-top: 10px;
}

.rightSpan {
  float: right;
  padding-right: 10px;
  padding-top: 10px;
}

.tong {
  padding-top: 100px;
}

.shareMember {
	overflow:scroll; height:200px;
	margin-bottom: 15px;
}

#tong2 {
	display: none;
}

ul.tabs{
  margin: 0px;
  padding: 0px;
  list-style: none;
}
ul.tabs li{
  background: none;
  color: #222;
  display: inline-block;
  padding: 10px 10px;
  cursor: pointer;
}

ul.tabs li.current{
  background: #ededed;
  color: #222;
}

.tab-content{
  display: none;
  background: #ededed;
  padding: 15px;
}

.tab-content.current{
  display: inherit;
}

.shareInput {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
  width: 180px;
}

.icon-middle {
  vertical-align: -0.1em;
}


.noclick {
  pointer-events: none;
}

.autoclick {
  pointer-events: auto; 
}

.brgroup {
  display:none;
}

.brgroup.brshow {
  display:block;
}

.autoCount {
  font-size: 18px;
  color: green;
}